<template>
  <CRow v-if="item" class="mb-3">
    <CCol lg="12" md="12" sm="12">
      <h6>Welcome</h6>
      <h2 :class="{ 'text-center': isMobile }">
        {{ firstName }} {{ lastName }}
      </h2>
    </CCol>
    <CCol sm="12" lg="3" md="3" class="dark-block flex-grow-1">
      <CandidateInfoUserView :item="candidateInfoData" />
    </CCol>
    <CCol sm="12" lg="9" md="9" class="flex-grow-1 background-gray">
      <CRow class="background-default">
        <CCol sm="12" lg="12" md="12">
          <div class="message">
            <p>
              Please ensure your CV and supporting documents are uploaded with
              as many fields completed as this will assist in ensuring you are
              kept up to date with jobs and other information, thank you
            </p>
            <p>Please keep checking this site for updates.</p>
            <p>
              Manage your applications by clicking on the tabs above. Thank you!
            </p>
          </div>
        </CCol>
      </CRow>
      <CRow class="shortDetails mr-1">
        <CCol sm="10" lg="8" md="8">
          <div v-if="!isStudent">
            <CRow>
              <CCol class="lable" col="5">Speciality </CCol>
              <CCol class="value" col="7">
                {{ isStudent ? "Student in Training" : speciality || "--" }}
              </CCol>
            </CRow>

            <CRow class="padding-top" v-if="isPhysician">
              <CCol class="lable" col="5"> Sub Speciality</CCol>
              <CCol class="value" col="7">
                {{ candidateInfoData.sub_speciality || "N/A" }}
              </CCol>
            </CRow>

            <CRow class="padding-top" v-else-if="isNurse">
              <CCol class="lable" col="5"> Other Expertise</CCol>
              <CCol class="value" col="7">
                {{ otherExpertise }}
              </CCol>
            </CRow>

            <CRow class="padding-top" v-else-if="isAHP">
              <CCol class="lable" col="5">Sub Type / Licensed as</CCol>
              <CCol class="value" col="7">
                {{ candidateInfoData.sub_speciality || "--" }}
              </CCol>
            </CRow>

            <CRow class="padding-top">
              <CCol class="lable" col="5"> Qualification </CCol>
              <CCol class="value" col="7">
                {{
                  candidateInfoData.highest_qualification_acronym ||
                  qualificationName ||
                  "--"
                }}
              </CCol>
            </CRow>

            <CRow class="padding-top">
              <CCol class="lable" col="5"> Country Obtained</CCol>
              <CCol class="value" col="7">
                {{ qualificationCountry || "--" }}
              </CCol>
            </CRow>

            <CRow class="padding-top">
              <CCol class="lable" col="5"> Date Obtained </CCol>
              <CCol class="value" col="7">
                {{ qualificationDate || "--" }}
              </CCol>
            </CRow>
          </div>

          <div v-if="isStudent && !studentCourse">
            <CRow class="padding-top">
              <CCol class="lable" col="5">Study Location</CCol>
              <CCol class="value" col="7">
                {{ studentQualification.country_name || "--" }}
              </CCol>
            </CRow>

            <CRow class="padding-top" v-if="isNurse || isAHP">
              <CCol class="lable" col="5">Training Area</CCol>
              <CCol class="value" col="7"> {{ studentQualification.speciality_name }} </CCol>
            </CRow>

            <CRow class="padding-top">
              <CCol class="lable" col="5">Course level</CCol>
              <CCol class="value" col="7">
                {{ studentQualification.qualification_name || "--" }}
              </CCol>
            </CRow>

            <CRow class="padding-top">
              <CCol class="lable" col="5">Course Start Date</CCol>
              <CCol class="value" col="7">
                {{ formatDateMonthYear(studentQualification.course_start_date) || "--" }}
              </CCol>
            </CRow>

            <CRow class="padding-top">
              <CCol class="lable" col="5">Course End Date</CCol>
              <CCol class="value" col="7">
                {{ formatDateMonthYear(studentQualification.predicted_completion_date) || "--" }}
              </CCol>
            </CRow>

            <CRow class="padding-top">
              <CCol class="lable" col="5">Sponsored Course</CCol>
              <CCol class="value" col="7">
                {{ isSponsored ? `Yes` : "No" }}
              </CCol>
            </CRow>

            <CRow v-if="isSponsored" class="padding-top">
              <CCol class="lable" col="5">Sponsorship Start Date</CCol>
              <CCol class="value" col="7">
                {{ formatDateMonthYear(sponsoredDetails?.start_date) }}
              </CCol>
            </CRow>

            <CRow v-if="isSponsored" class="padding-top">
              <CCol class="lable" col="5">Sponsorship Expiry Date</CCol>
              <CCol class="value" col="7">
                {{ formatDateMonthYear(sponsoredDetails?.end_date) }}
              </CCol>
            </CRow>
          </div>

          <div v-if="isStudent && studentCourse">
            <CRow class="padding-top">
              <CCol class="lable" col="5">Course Title</CCol>
              <CCol class="value" col="7">
                {{ trainingCourse.course_title || "--" }}
                {{ trainingCourse.course_ref ? `-( ${trainingCourse.course_ref} )` :
                  " " }}
              </CCol>
            </CRow>

            <CRow class="padding-top">
              <CCol class="lable" col="5">Mode</CCol>
              <CCol class="value" col="7">
                {{ studentCourse.full_part_time || "--" }}
              </CCol>
            </CRow>

            <CRow class="padding-top">
              <CCol class="lable" col="5">Course Start Date</CCol>
              <CCol class="value" col="7">
                {{ formatDateMonthYear(studentCourse.course_start_date) || "--" }}
              </CCol>
            </CRow>

            <CRow class="padding-top">
              <CCol class="lable" col="5">Course End Date</CCol>
              <CCol class="value" col="7">
                {{ formatDateMonthYear(studentCourse.course_end_date) || "--" }}
              </CCol>
            </CRow>

            <CRow class="padding-top">
              <CCol class="lable" col="5">Distance/Online Learning</CCol>
              <CCol class="value" col="7">
                {{ studentCourse.distance_learning ? `Yes` : "No" }}
              </CCol>
            </CRow>

            <CRow class="padding-top">
              <CCol class="lable" col="5">Sponsored Course</CCol>
              <CCol class="value" col="7">
                {{ isSponsored ? `Yes` : "No" }}
              </CCol>
            </CRow>

            <CRow v-if="isSponsored" class="padding-top">
              <CCol class="lable" col="5">Sponsorship Start Date</CCol>
              <CCol class="value" col="7">
                {{ formatDateMonthYear(sponsoredDetails?.start_date) }}
              </CCol>
            </CRow>

            <CRow v-if="isSponsored" class="padding-top">
              <CCol class="lable" col="5">Sponsorship Expiry Date</CCol>
              <CCol class="value" col="7">
                {{ formatDateMonthYear(sponsoredDetails?.end_date) }}
              </CCol>
            </CRow>
          </div>

          <CRow v-if="isMobile" class="pt-2 mt-4">
            <CCol class="lable" md="6">
              <div class="text-center btncol">
                <router-link :to="`/candidate/${item.candidate_uid}`"
                  class="btn rounded-0 w-100 px-2 btn-primary btn-font-size">View Profile</router-link>
              </div>
            </CCol>
            <CCol class="lable" md="6">
              <div class="text-center btncol">
                <CButton class="btn rounded-0 w-100 px-2 btn-primary btn-font-size" @click="navigateToEditProfile()">Edit
                  Profile</CButton>
              </div>
            </CCol>
            <CCol class="value" md="6">
              <div class="text-center btncol">
                <router-link to="jobs-list" class="btn rounded-0 w-100 px-2 btn-primary btn-font-size">View & Apply
                  Jobs</router-link>
              </div>
            </CCol>
            <CCol class="lable" md="6">
              <div class="pb-2 text-center btncol mt-3">
                <router-link to="/tfw/partner/profile" class="btn rounded-0 w-100 px-4 btn-primary btn-lg btn-font-size">TalentFind
                Marketplace</router-link>
              </div>
              <!-- <div v-else class="pb-2 text-center btncol mt-3"> 
                <router-link
                  to="list-course"
                  class="btn rounded-0 w-100 px-4 btn-primary btn-lg btn-font-size"
                  >View & Apply for Language Training Course</router-link
                >
              </div> -->
            </CCol>
            <CCol class="lable" md="6">
              <div class="pb-2 text-center btncol mt-3">
                <CButton class="btn rounded-0 w-100 px-4 btn-primary btn-lg btn-font-size"
                  @click="navigateToEditProfile(1)">Add or Edit Language Results/Scores</CButton>
              </div>
            </CCol>
          </CRow>
        </CCol>
        <CCol v-if="!isMobile" class="pt-1 pb-2" sm="12" lg="3" md="3">
          <div class="pb-2 text-center btncol mt-0">
            <router-link :to="`/candidate/${item.candidate_uid}`"
              class="btn rounded-0 w-100 px-4 btn-primary btn-lg btn-font-size">View Profile</router-link>
          </div>
          <div class="pb-2 text-center btncol mt-3">
            <CButton class="btn rounded-0 w-100 px-4 btn-primary btn-lg btn-font-size" @click="navigateToEditProfile()">
              Edit Profile</CButton>
          </div>
          <div class="text-center btncol">
            <router-link to="jobs-list" class="btn rounded-0 w-100 px-4 btn-primary btn-lg btn-font-size">View & Apply
              Jobs</router-link>
          </div>
          <div class="pb-2 text-center btncol mt-3">
            <router-link to="/tfw/partner/profile" class="btn rounded-0 w-100 px-4 btn-primary btn-lg btn-font-size">TalentFind
            Marketplace</router-link>
          </div>
          <!-- <div v-else class="pb-2 text-center btncol mt-3"> 
            <router-link
              to="list-course"
              class="btn rounded-0 w-100 px-4 btn-primary btn-lg btn-font-size"
              >View & Apply for Language Training Course</router-link
            >
          </div> -->
          <div class="pb-2 text-center btncol mt-3">
            <CButton class="btn rounded-0 w-100 px-4 btn-primary btn-lg btn-font-size" @click="navigateToEditProfile(1)">
              Add or Edit Language Results/Scores</CButton>
          </div>
          <div class="pb-2 text-center btncol mt-3">
            <CButton class="btn rounded-0 w-100 px-4 btn-primary btn-lg btn-font-size" @click="openNotificationModal()">
              Take Free Language Assessment Test</CButton>
          </div>
        </CCol>
      </CRow>
    </CCol>
    
    <TfwLoginModal v-if="loginConfirmModal.isShowPopup" :isShowPopup="loginConfirmModal.isShowPopup"
      modalColor="modal-primary" @modalCallBack="loginConfirmModalCallBack" :aspectRatio="2" :item="item"
      :buttonLabel="loginConfirmModal.buttonLabel" />
    <CampaignWelcome
      :isShowPopup="campaignModal.isShowPopup"
      :item="campaignModal"
      @modalCallBack="closeModalCallBack"
    /> 
    <AssessmentTestModal 
      v-if="notificationModal.isShowPopup"
      :isShowModal="notificationModal.isShowPopup"
      @modalCallBack="notificationModalCallback"
      @closeModal="handleConfirmModal"
    />
    <CModal
      v-if="assessmentConfirmModal.isShowPopup"
      color="primary"
      :show.sync="assessmentConfirmModal.isShowPopup"
      :close-on-backdrop="false"
    >
      <template #header>
        <h6 class="modal-title">{{ assessmentConfirmModal.modalTitle }}</h6>
        <CButtonClose @click="assessmentModalCallBack()" class="text-black" />
      </template>
      <div class="text-center">
        <h5 class="mb-3">{{ assessmentConfirmModal.modalContent }}</h5>
      </div>
      <template #footer-wrapper>
        <div class="border-top d-flex justify-content-end py-3 mx-3">
          <div class="d-flex">
            <CButton
              type="button"
              variant="outline"
              color="primary"
              class="px-4"
              @click="assessmentModalCallBack()"
            >
              Ok
            </CButton>
          </div>
        </div>
      </template>
    </CModal>
  </CRow>
</template>

<script>
import CandidateInfoUserView from "@/containers/CandidateList/CandidateInfoUserView";
import router from "@/router/index";
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import {
  getScope,
  Role,
  getLocalOrgDetail,
  CONSTANT as CONST,
} from "@/helpers/helper";
import candidateFilter from "@/schema/candidateFilter";
import ResetVue from "../../views/pages/Reset.vue";
import PreviewModal from "@/components/reusable/PreviewModal";
import pdf from "vue-pdf";
import { BASE_URL } from "@/service_urls";
import Modal from "@/components/reusable/Modal";
import TfwLoginModal from "../../components/reusable/TfwLoginModal.vue";
import CampaignWelcome from "./CampaignWelcome.vue";
import AssessmentTestModal from "@/components/reusable/modal/AssessmentTestModal"
export default {
  name: "CandidateListUserView",
  data() {
    return {
      currentUserRole: getScope(),
      Role,
      isMobile: false,
      previewModal: {
        modalColor: "primary",
        modalTitle: "Document Preview",
        isShowPopup: false,
        buttons: ["OK"],
        isPreview: false,
        size: "lg",
        closeOnBackdrop: false,
      },
      iframe: {
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: false,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      noCVModal: {
        modalColor: "primary",
        modalTitle: "Document Preview",
        modalContent: "There is No CV / Resume Available for this Candidate",
        isShowPopup: false,
        buttons: ["OK"],
        stepperModal: true,
      },
      loginConfirmModal: {
        isShowPopup: false,
        buttonLabel: "Ok",
      },
      notificationModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      campaignModal: {},
      assessmentConfirmModal: {
        isShowPopup: false,
        modalTitle: "NOTIFICATION",
        modalContent: "You are Already Completed the Assessment !",
      },
    };
  },
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  components: {
    CandidateInfoUserView,
    PreviewModal,
    Modal,
    TfwLoginModal,
    CampaignWelcome,
    AssessmentTestModal
},
  computed: {
    ...mapGetters([
      "getUserId",
      "getCustomerId",
      "getCustomerTypeIDFromAccessToken",
      "candidateProfile",
      "getUser",
      "getCampaignWelcomeDetails",
    ]),
    speciality() {
      if (this.isNurse)
        return this.item?.sub_speciality
          ? `${this.item?.speciality?.speciality} - ${this.item?.sub_speciality?.sub_speciality}`
          : this.item?.speciality?.speciality;
      return this.item?.speciality?.speciality;
    },
    firstName() {
      let firstname = this.item?.first_name;
      if (firstname) {
        firstname = firstname
          .replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          })
          .replace(/\s/g, "");
        return firstname;
      }
      return false;
    },
    lastName() {
      let lastname = this.item?.surname;
      if (lastname) {
        lastname = lastname
          .replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          })
          .replace(/\s/g, "");
        return lastname;
      }
      return false;
    },
    pqrLevels() {
      if (Array.isArray(this.item.pqr_levels) && this.item.pqr_levels.length) {
        const pqr_levels =
          this.item.pqr_levels.filter(({ potential }) => !potential) || [];
        return pqr_levels.length
          ? pqr_levels[0].pqr_detail?.level?.candidate_level ||
          pqr_levels?.pqr_ahp_detail?.description ||
          "--"
          : "--";
      } else
        return (
          this.item.level_name || this.item.candidate_level?.candidate_level
        );
    },
    isPQR() {
      if (this.item.candidate_type_id == 1) {
        return this.item.pqr_levels ? this.item.pqr_levels.length : false;
      } else if (this.item.candidate_type_id == 6) {
        return false;
      }
      return true;
    },
    candidateInfoData() {
      let {
        first_name,
        surname,
        email,
        gender: { gender },
        title: { title },
        speciality: { speciality },
        sub_speciality: { sub_speciality },
        candidate_level: { candidate_level },
        candidate_type: { candidate_type },
        nationality,
        location: { location },
        dob,
        age,
        main_qualification: { qualification },
        main_qualification_year,
        phone,
        eligibility_letter_available_for,
        local_license,
        dataflow_complete,
        candidate_uid,
        whatsapp,
        available,
        job_type,
        job_time,
        flexible,
        extra_hours_available,
        retired_clinician,
        last_date_of_practise,
        volunteer,
        preferred_locations,
        profile_picture,
        job_reference,
        highest_qualification_name,
        highest_qualification_acronym,
        last_date_of_login,
        created_on,
        display_uid,
      } = Object.assign(this.item, {
        title: this.item.title || {},
        speciality: this.item.speciality || {},
        sub_speciality: this.item.sub_speciality || {},
        candidate_level: this.item.candidate_level || {},
        nationality: this.item.nationality_names || [],
        main_qualification: this.item.main_qualification || {},
        gender: this.item.gender || { gender: "" },
        location: this.item.location || {},
        candidate_uid: this.item.candidate_uid || null,
        display_uid: this.item.display_uid || null,
        whatsapp: this.item.whatsapp || "",
        available: this.item.available || null,
        local_license: this.item.local_license,
        dataflow_complete: this.item.dataflow_complete,
        flexible: this.item.flexible,
        extra_hours_available: this.item.extra_hours_available || "--",
        retired_clinician: this.checkData(this.item.retired_clinician),
        volunteer: this.checkData(this.item.volunteer),
        highest_qualification_name:
          this.item.highest_qualification_name || null,
        highest_qualification_acronym:
          this.item.highest_qualification_acronym || null,
        last_date_of_login: this.item.last_date_of_login,
        created_on: this.item.created_on,
      });

      var availability_summary = [];
      if (job_type) {
        availability_summary.push(job_type);
      }
      if (job_time) {
        availability_summary.push(job_time);
      }
      if (
        this.item.preferred_locations &&
        this.item.preferred_locations.length
      ) {
        preferred_locations = this.item.preferred_locations
          .map((value) => value?.location?.comments)
          .join(", ");
      } else preferred_locations = "--";

      eligibility_letter_available_for = eligibility_letter_available_for
        ? eligibility_letter_available_for.split(",").join(", ")
        : "--";
      if (this.item?.doh_candidate_status?.job_id) {
        job_reference = `${this.item.doh_candidate_status?.job_title} | 
        ${this.item.doh_candidate_status?.customer_id} - ${this.item.doh_candidate_status?.job_id}`;
      } else job_reference = "--";
      return {
        jobStatus: this.jobStatus,
        first_name,
        surname,
        gender,
        title,
        speciality,
        sub_speciality,
        candidate_level,
        candidate_type,
        nationality,
        dob,
        email,
        age,
        qualification,
        main_qualification_year,
        phone,
        whatsapp,
        eligibility_letter_available_for,
        local_license,
        dataflow_complete,
        flexible,
        candidate_uid,
        availability_summary,
        extra_hours_available,
        location,
        retired_clinician,
        last_date_of_practise,
        volunteer,
        preferred_locations,
        profile_picture,
        job_reference,
        highest_qualification_name,
        highest_qualification_acronym,
        last_date_of_login,
        created_on,
        display_uid,
      };
    },
    specialisation() {
      let specialisation_data = null;
      this.item.qualifications.some((e) => {
        if (e.on_specialist_register) {
          specialisation_data = e;
          return true;
        }
        return false;
      });
      return specialisation_data;
    },
    splqualification_classification_name() {
      return (
        this.specialisation?.qualification?.qualification_classification_name ||
        null
      );
    },
    splqualification_country_name() {
      return this.specialisation?.country_name || null;
    },
    highestQualification() {
      let highestQualification = null;
      this.item.qualifications &&
        this.item.qualifications.length &&
        this.item.qualifications.some((e) => {
          if (e.main) {
            highestQualification = e;
            return true;
          }
          return false;
        });
      return highestQualification;
    },
    qualification() {
      return this.highestQualification?.qualification?.acronym || null;
    },
    qualificationCountry() {
      return this.highestQualification?.country_name || null;
    },
    qualificationDate() {
      let issued_month = this.highestQualification?.month_awarded || null;
      let issue_year = this.highestQualification?.year_awarded || null;
      if (issued_month || issue_year)
        return `${issued_month ? moment.monthsShort()[issued_month - 1] : null
          } ${issue_year}`;
      return null;
    },
    cvDocument() {
      if (this.item.cv_document && this.item.cv_ext)
        return { link: this.item.cv_document, document_ext: this.item.cv_ext };
      return false;
    },
    isNurse() {
      return this.item?.candidate_type_id
        ? this.item?.candidate_type_id === 2
        : false;
    },
    isPhysician() {
      return this.item?.candidate_type_id
        ? this.item?.candidate_type_id === 1
        : false;
    },
    isStudent() {
      return this.item?.is_student || false;
    },
    qualificationName() {
      if (
        this.item?.qualifications &&
        this.item?.highest_qualification_name &&
        this.item?.highest_qualification_name == "Other - not listed"
      ) {
        return _(this.item?.qualifications)
          .filter((qualification) => {
            return (
              qualification?.qualification_name ==
              this.item?.highest_qualification_name
            );
          })
          .map((qualification) => {
            return qualification?.other_qualification_name
              ? "Other - " + qualification?.other_qualification_name
              : null;
          })
          .value()
          .toString();
      } else {
        return this.item?.highest_qualification_name;
      }
    },
    isAHP() {
      return this.item?.candidate_type_id
        ? this.item?.candidate_type_id === 3
        : false;
    },
    isDentist() {
      return this.item?.candidate_type_id
        ? this.item?.candidate_type_id === 4
        : false;
    },
    otherExpertise() {
      return (
        this.item?.candidate_specialities
          .filter(({ main }) => !main)
          .map(
            ({ speciality, sub_speciality }) =>
              `${speciality?.speciality}${sub_speciality ? ` - ${sub_speciality?.sub_speciality}` : ""
              }`
          )
          .join(", ") || "--"
      );
    },
    isEducationalType() {
      return (
        this.candidateProfile?.customer_type_id ===
        CONST.CUSTOMER_TYPES.educational_institute || false
      );
    },
    isServiceProvider() {
      return (
        this.candidateProfile?.customer_type_id ===
        CONST.CUSTOMER_TYPES.language_school || false
      );
    },

    getPathAcronym() {
      let { path_acronym } = getLocalOrgDetail();
      return path_acronym;
    },
    //Static functions

    isLanguageSchool() {
      return (
        this.candidateProfile?.customer_type_id === CONST.CUSTOMER_TYPES.language_school);
    },
    studentQualification() {
      return this.item?.qualifications?.[0] || {}
    },
    sortedQualification(){
      const qual = this.item?.qualifications?.length ? [...this.item?.qualifications]: []
      return qual?.sort((a, b) => b.candidate_qualification_id - a.candidate_qualification_id);
    },
    isSponsored() {
      const latestRecord = this.sortedQualification?.[0];
      return latestRecord?.is_sponsored
    },
    sponsoredDetails() {
      const latestRecord = this.sortedQualification?.[0];
      return latestRecord?.sponsored
    },
    studentCourse() {
      return this.item?.student_course
    },
    trainingCourse() {
      return this.item?.student_course.training_course
    },
  },
  mounted() {
    let isMobile = window.matchMedia(
      "only screen and (max-width: 420px)"
    ).matches;
    this.isMobile = isMobile;
    this.loginConfirmModalCallBack();
    if(this.getCampaignWelcomeDetails) {
      this.campaignModal = {
        isShowPopup: this.getCampaignWelcomeDetails?.is_campaign,
        campaignName: this.getCampaignWelcomeDetails?.campaign_name,
        orgName: this.getCampaignWelcomeDetails?.org_name,
      };
    };

  },
  methods: {
    ...mapActions([
      "setCandidate",
      "setSelectedcandidateById",
      "setCandidateListBackUrl",
      "createshortlist",
      "releaseCandidate",
      "resetCandidateDetail",
      "createInterviewlist",
      "createRecruitList",
      "fetchFacilityConfig",
      "fetchJobs",
      "showToast",
      "updateUser",
      "fetchUser",
    ]),
    navigateToEditProfile(block) {
      this.$store.state.candidate.block = block ? block : 0;
      router.push("/profile");
    },
    navigateToDetails(item) {
      this.setCandidateListBackUrl(this.$route.fullPath);
      this.resetCandidateDetail();
      this.$router.push({ path: `/candidate/${item.candidate_uid}` });
    },

    checkData(value) {
      if (value !== null) {
        return value === "Yes" || value === true ? "Yes" : "No";
      }
      return "--";
    },
    navigateToJobList({ job_id }) {
      this.$router.push({ path: `/jobs-list/?job_id=${job_id}` });
    },
    getDtSpl(qualification) {
      if (qualification != null) {
        let month_num = qualification.specialist_registration_month || null;
        let month_str = null;
        if (month_num) month_str = moment.monthsShort()[month_num - 1];
        return `${month_str} ${qualification.specialist_registration_year}`;
      }
      return null;
    },
    previewCV(data) {
      if (data) {
        this.preview(data);
      } else {
        this.noCVModal.isShowPopup = true;
      }
    },
    preview(data) {
      if (["PNG", "JPG", "JPEG", "GIF"].includes(data?.document_ext)) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.src = data.link;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (data?.document_ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(data.link);
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (
        ["TXT", "EPS", "TIF", "TIFF", "RAW"].includes(data?.document_ext)
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.previewModal.isPreview = false;
        this.previewModal.isShowPopup = false;
      } else {
        this.iframe.isImg = false;
        this.iframe.ispdf = false;
        this.iframe.loaded = true;
        this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=" +
          window.location.host +
          this.item.link_to_cv_doc +
          "";
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      }
    },
    noCVModalCallback(action) {
      this.noCVModal.isShowPopup = false;
    },
    modalCallBack(action) {
      this.previewModal.isPreview = false;
      this.previewModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
    async loginConfirmModalCallBack(action) {
      if (this.getPathAcronym === "tfw") {
        if (this.getUser?.tfw_first_login === false) {
          this.loginConfirmModal.isShowPopup = true;
          if (action === false || action === "Cancel") {
            this.loginConfirmModal.isShowPopup = false;
            await this.updateUser({
              tfw_first_login: true,
            });
            this.fetchUser()
          }
        }
      }
    },
    formatDateMonthYear(date) {
      return date ? moment(date).format("DD MMM YYYY") : "--";
    },
    closeModalCallBack() {
      this.campaignModal.isShowPopup=false
    },
    openNotificationModal(){
      this.notificationModal.isShowPopup=true
    },
    notificationModalCallback(action){
      this.notificationModal.isShowPopup=false;
    },
    handleConfirmModal(value) {
      this.notificationModal.isShowPopup = value;
      this.assessmentConfirmModal.isShowPopup = true;
    },
    assessmentModalCallBack() {
      this.assessmentConfirmModal.isShowPopup = false;
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format("MMM YYYY");
    },
  },
  watch: {
    getUser: {
      immediate: true,
      handler(newUser) {
        if (newUser) {
          this.loginConfirmModalCallBack();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.background-default {
  background-color: #f9f8fd;
}

.background-gray {
  background-color: #eee;
}

h1 {
  text-shadow: 1px 2px #dcdada;
}

.btnmargin {
  margin-right: 15px;
  padding-top: 0.1rem;
  padding-right: 0.5rem;
  padding-bottom: 0.05rem;
  padding-left: 0.5rem;
  font-size: 12px;
}

.btncol {
  vertical-align: middle;
  max-width: 243px;
  margin: 0px auto;
  clear: both;
  margin-top: 1rem;
}

.lblspan {
  font-weight: bold;
}

.flex-1 {
  font-size: 13.5px;
}

.flex-2 {
  font-size: 13.5px;
}

.add-details-btn {
  max-width: 200px;
}

.icon-wrapper {
  .icon {
    width: 50px;

    img {
      width: 35px;
      padding: 5px;
      text-align: center;
      cursor: pointer;
    }

    img.disabled {
      // pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5 !important;
    }
  }
}

.border-top-primary {
  // border-top: 1px solid #d8dbe0 !important;
  // border-color: #dd3651 !important;
}

.btn-font-size {
  font-size: 15px;
}

.left-side-width {
  width: 40%;
}

.right-side-width {
  width: 70%;
}

.action-btn {
  width: 100%;
  font-size: 11px;
}

.btn:disabled {
  cursor: not-allowed;
}

.shortDetails {
  background: #eee;
  border-top: 1.5px #d2cfcf dotted;
  padding: 20px;

  .lable {
    font-weight: bold;
  }
}

.view-profile {
  cursor: pointer;
  font-weight: 600;
}

.padding-top {
  padding-top: 0.8rem !important;
}
</style>
